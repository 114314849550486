import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import { auth } from "../firebase"
import {
  Container,
  Row,
  Col,
} from "react-bootstrap"
import TitleLine from "../components/utils/TitleLine"
import "../styles/SignUpPage.css"
import NewAsyncButton from "../components/utils/NewAsyncButton.js"
import * as firebaseLogin from "../scripts/login"
import VerifyEmail from "../components/onboarding/VerifyEmail"
import InputCreator from "../components/styledInputs/InputCreator"
import dots from "../images/bgs/dots.svg"

class TeacherLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
      Email: "",
      Password: "",
      verifyEmail: false,
    }
    this.login = this.login.bind(this)
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true })
      }
    })
  }

  login(resetLoad) {
    //var localState = this.state
    if(!this.state.Email || !this.state.Password) {
      resetLoad("Fill in all fields")
    } else {
    firebaseLogin
      .default(this.state.Email, this.state.Password)
      .then(() => {
        resetLoad("Logging you in")
        let currentUser = auth.currentUser
        if (currentUser.emailVerified) {
          navigate("/teacher-dashboard/")
        } else {
          this.setState({ verifyEmail: true })
        }
      })
      .catch(error => {
        let message = error.code
        if(message === "auth/invalid-email") {
          console.log(error)
          resetLoad("Invalid Email. Try Again?")
        } else if(message == "auth/user-not-found") {
          console.log(error)
          resetLoad("User not Found")
        } else {
          resetLoad("Error logging in. Try Again?")
        }
      })
    }
  }
  handleForm = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    var title = "Teacher Login"
    var description = "Log in to continue posting projects and saving time"
    var regularInput = ["Email", "Password"]
    var type = ["text", "password"]
    if (!this.state.verifyEmail) {
      return (
        <Layout image={dots} full> 
          <SEO title="Teacher Login" />
          <Container className="topSpacing">
            <TitleLine title={title} description={description} />
            <ul>
              <Row className="sidesPadding">
                <InputCreator
                  titles={regularInput}
                  errors={[false, false]}
                  types={type}
                  names={["Email", "Password"]}
                  struct={[1, 1]}
                  callback={this.handleForm}
                  values={{
                    Email: this.state.Email,
                    Password: this.state.Password,
                  }}
                />
              </Row>
            </ul>
            <Row className="center sidesPadding justify-content-center">
              <NewAsyncButton
                buttonTexts={[
                  "Log In",
                  "Logging you in",
                  "Fill in All Fields", 
                  "Invalid Email. Try Again?",
                  "Invalid Password. Try Again?",
                  "User not Found",
                  "Error logging in. Try Again?",
                  "Logged In!"
                ]}
                buttonVariants={
                ["primary", "primary","danger","primary","primary","danger","danger", "success"]}
                buttonColor = {
                [ "","", "","","","","", ""]}
                callback={this.login}
                classname="buttonTop"
              />
            </Row>
            <Row
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginTop: "40px",
              }}
              className="sidesPadding justify-content-center"
            >
              <Col xl={12}>
                <a href="/create-teacher-account">
                  Don't have an account? Sign up here!
                </a>
              </Col>
              <br></br>
              <Col xl={12}>
                <a href="/SendResetEmail">Forgot your password? Click here.</a>
              </Col>
            </Row>
          </Container>
        </Layout>
      )
    } else {
      return (
        <Layout>
          <SEO title="Teacher Login" />
          <VerifyEmail
            email={this.state.Email}
            password={this.state.Password}
            navigate="/teacherCongrats/"
          />
        </Layout>
      )
    }
  }
}

export default TeacherLogin
